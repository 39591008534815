import React, { useCallback, useEffect, useState } from "react";

import find from 'lodash/find'
import first from 'lodash/first'
import get from 'lodash/get'

// Components Imports
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// Local Imports
import textToPath from "./utils";
import { getPatternFill, PATTERNS_CONFIG } from "../constants";
import postMessageHelper from "./postMessageHelper";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

const Pricing = (props) => {
  const {font, selectedPattern, text, products} = props;

  const [fontSvg, setFontSvg] = useState('')
  const [parentProductId, setParentProductId] = useState(get(first(products), 'id', null))
  const [variants, setVariants] = useState([])


  const {name: pattern} = selectedPattern
  const key = `${pattern} svg`;
  const patternConfig = PATTERNS_CONFIG[pattern];

  useEffect(() => {
    async function fetchData() {
      const svg = await textToPath(key, text, getPatternFill(pattern), font);
      setFontSvg(svg?.innerHTML)
    }

    text && fetchData();
  }, [text, pattern, font, key])

  useEffect(() => {

  }, [parentProductId])

  useEffect(() => {
    if (parentProductId !== null) {
      const parent = find(products, (product) => {
        return product.id === parentProductId
      })
      const variants = parent.variants.filter((item) => {
        return item.option2 === selectedPattern?.type
      })
      setVariants(variants)
    } else {
      setVariants([])
    }
  }, [parentProductId, selectedPattern, products])

  const handleSelectParentProduct = useCallback((event) => {
    setParentProductId(parseInt(event.target.value, 10))
  }, [setParentProductId])

  const onPostMessage = useCallback((variant) => {
    postMessageHelper('ADD_ITEM_TO_CART',
      {
        variant,
        pattern,
        text,
        fontSvg, font:
        font?.names?.fullName?.en
      }
    )
  }, [pattern, text, fontSvg, font])

  return (
    <>
      <div className="choose-font">
        <Typography variant="h5">Selected Font and Finish:</Typography>
      </div>
      <div className="final-design-container">
        <div className="text-container-finish">
          <div style={{backgroundColor: patternConfig?.invertBackground ? '#000000' : null}}>
            <svg id={key} overflow="visible">
              <defs>
                {patternConfig.texture}
              </defs>
            </svg>
          </div>
        </div>
      </div>
      <div className="choose-font">
        <Typography variant="h5">Selected Thickness:</Typography>
      </div>
      <div>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={parentProductId}
          onChange={handleSelectParentProduct}
        >
          {products.map(item => {
            return <FormControlLabel value={item.id} control={<Radio/>} label={item.title}/>
          })}
        </RadioGroup>
      </div>
      <div className="pricing-table">
        <TableContainer component={Paper}>
          <Table sx={{minWidth: 300}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Width</TableCell>
                <TableCell align="center">Approx. Height</TableCell>
                <TableCell align="right">Purchase</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {variants.map((variant) => (
                <TableRow
                  key={variant.title}
                  sx={{'&:last-child td, &:last-child th': {border: 0}}}
                >
                  <TableCell component="th" scope="row">
                    {variant?.option1.slice(0, variant?.option1.indexOf("x"))}
                  </TableCell>
                  <TableCell align="center">{variant?.option1.slice(variant?.option1.indexOf("x") + 1)}</TableCell>
                  <TableCell align="right">
                    <div className="price-btn">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          onPostMessage(variant)
                        }}
                      >
                        BUY NOW {variant.price} $
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

export default Pricing;
