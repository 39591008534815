async function textToPath(key, text, fillText, font) {
  const size = {x: 50, y: 25}
  let params = {
    string: text,
    fontSize: size.x,
    decimals: 1,
    singleGylyphs: false
  }
  const factor = text.length > 20 ? 3 : text.length > 10 ? 2 : 1

  let options = params.options;
  let unitsPerEm = font.unitsPerEm;
  let ratio = params.fontSize / unitsPerEm;
  let ascender = font.ascender;
  let descender = Math.abs(font.descender);
  let ratAsc = ascender / unitsPerEm;
  let ratDesc = descender / unitsPerEm;
  let yOffset = params.fontSize * ratAsc;
  let lineHeight = params.fontSize + params.fontSize * ratDesc + 50;
  let singleGylyphs = params.singleGylyphs;

  let teststring = params.string.split("");

  let glyphs = font.stringToGlyphs(params.string);
  let leftSB = glyphs[0].leftSideBearing * ratio;
  let textPath = "";
  const fillString = `fill="${fillText}"`

  // individual paths for each glyph
  if (singleGylyphs) {
    let paths = font.getPaths(
      params.string,
      -leftSB,
      yOffset,
      params.fontSize,
      options
    );
    paths.forEach(function (path, i) {
      let pathEl = path.toSVG(params.decimals);
      textPath += pathEl.replaceAll(
        "d=",
        'class="glyph glyph-' + teststring[i] + fillString + '" d='
      );
    });
  }
  //word (all glyphs) merged to one path
  else {
    let path = font.getPath(
      params.string,
      -leftSB,
      yOffset,
      params.fontSize,
      options
    );
    textPath += path
      .toSVG(params.decimals)
      .replaceAll("d=", `fill="${fillText}" class="glyph" d=`);
  }

  let fontSvg = document.getElementById(key)
  fontSvg.innerHTML += textPath;

  // adjust bbox
  let bb = fontSvg.getBBox();
  let stringWidth = Math.ceil(bb.width + bb.x) / factor;
  fontSvg.setAttribute("viewBox", "0 -10 " + stringWidth + " " + lineHeight);
  fontSvg.setAttribute("data-asc", ratAsc);

  let textPathSvg = fontSvg.querySelector(".glyph");
  let textPathLength = textPathSvg.getTotalLength();

  return fontSvg
}

export default textToPath;
