import React, { useEffect, useState } from "react";

// Components Imports
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// Local Imports
import SelectFont from "./selectFont";
import SelectFinish from "./selectFinish";
import Pricing from "./pricing";
import usePostMessageListener from "../hooks/usePostMessageListener";
import postMessageHelper from "./postMessageHelper";


const MainApp = () => {
  const steps = ['Select a font', 'Choose your finish', 'Select size'];

  const [text, setText] = useState('Custom');
  const [fontFile, setFontFile] = useState(null);
  const [collection, setCollection] = useState([])
  const {type, data} = usePostMessageListener()

  const [selectedFont, setSelectedFont] = useState(null);
  const [selectedFinish, setSelectedFinish] = useState(null);

  const [activeStep, setActiveStep] = React.useState(0);

  useEffect(() => {
    postMessageHelper('FETCH_COLLECTION', {})
  }, [])

  useEffect(() => {
    if (type === 'FETCH_COLLECTION' && data['error'] === undefined) {
      setCollection(data?.products)
    }

  }, [type, data])

  const handleNextFinish = (pattern, font) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSelectedFinish(pattern);
    setFontFile(font);
  }

  const handleNextFont = (fontName) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSelectedFont(fontName);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    window.scrollTo(0, 0)
  };

  const onChangeHandler = (e) => {
    setText(e.target.value);
  }
  const renderBackNavigation = (text) => {
    return <Box>
      <Button
        variant="outlined"
        color="primary"
        disabled={activeStep === 0}
        onClick={handleBack}
        sx={{mr: 1}}
        mt={2}
      >
        {text || "Back"}
      </Button>
    </Box>
  }

  return (
    <>
      {renderBackNavigation()}
      <Stepper className="stepper" activeStep={activeStep}>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === 0 && (
          <>
            <Typography variant="h5" gutterBottom component="div">
              Enter Name or Text
            </Typography>
            <div className="input-container">
              <TextField
                autoComplete="off"
                onChange={onChangeHandler}
                value={text}
                id="input-text"
                placeholder="Your name here"
                fullWidth
                variant="outlined"
              />
            </div>
            <SelectFont
              selectedFont={selectedFont}
              text={text || 'Custom'}
              handleNext={handleNextFont}
            />
          </>
        )}
        {activeStep === 1 && (
          <>
            <Typography variant="h5" gutterBottom component="div">
              Selected Name/Text: {text}
            </Typography>
            <SelectFinish
              font={selectedFont}
              selectedPattern={selectedFinish}
              text={text}
              handleNext={handleNextFinish}
            />
          </>
        )}
        {activeStep === 2 && (
          <Pricing
            font={fontFile}
            selectedPattern={selectedFinish}
            text={text}
            products={collection}
          />
        )}
      </div>
      {renderBackNavigation(
        activeStep === 1 ? 'BACK TO FONT STEP' : activeStep === 2 ? 'BACK TO FINISH/COLOR STEP' : ''
      )}
    </>
  );
}

export default MainApp;
