import React from "react";

// Components Imports
import Grid from "@mui/material/Grid";

// Local Imports
import { FONTS } from "../constants";
import FontCard from "./fontCard";
import Typography from "@mui/material/Typography";

const SelectFont = (props) => {
  const {selectedFont, text, handleNext} = props;

  return (
    <>
      <div className="choose-font">
        <Typography variant="h5">Choose Font</Typography>
      </div>
      <Grid container spacing={2}>
        {Object.keys(FONTS).map(fontName => {
          return (
            <Grid key={fontName} item sm={12} xs={12} md={6}>
              <div className="font-container">
                <div className='is-selected'>
                  {selectedFont === fontName && <img alt="selected font" src="/icons/checkmark.svg"/>}
                </div>
                <FontCard
                  fontName={fontName}
                  text={text}
                  setSelectedFont={handleNext}
                />
              </div>
            </Grid>
          )
        })}
      </Grid>
    </>
  );
}

export default SelectFont;
