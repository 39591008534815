import React, { useCallback, useEffect, useState } from "react";

// Components Imports
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// Local Imports
import { FONTS, PATTERNS } from '../constants';
import FinishCard from "./finishCard";
import { load } from "opentype.js";

const SelectFinish = (props) => {
  const { font, selectedPattern, text, handleNext } = props;
  const [fontFile, setFontFile] = useState(null);

  const loadFont = useCallback(async () => {
    const file = await load(FONTS[font]);
    setFontFile(file);
  }, [font])

  useEffect(() => {
    loadFont();
  }, [loadFont]);

  return (
    <>
      <div className="choose-font">
        <Typography variant="h5">Choose Finish</Typography>
      </div>
      <Grid container spacing={2}>
        {fontFile && Object.keys(PATTERNS).map(pattern => {
          return (
            <Grid key={pattern} item sm={12} xs={12} md={6}>
              <div className="font-container">
                <div className='is-selected'>
                  {selectedPattern?.name === pattern && <img alt="selected font" src="/icons/checkmark.svg"/>}
                </div>
                <FinishCard pattern={PATTERNS[pattern]} text={text} font={fontFile} setSelectedFinish={handleNext} />
              </div>
            </Grid>
          )
        })}
      </Grid>
    </>
  );
}

export default SelectFinish;
