import Affectionately from './fonts/Affectionately.otf'
import Ananda from './fonts/Ananda.ttf'
import Andalucia from './fonts/Andalucia.ttf'
import Anglena from './fonts/Anglena.otf'
import Ashburton from './fonts/Ashburton.otf'
import AthenaoftheOcean from './fonts/AthenaoftheOcean.ttf'
import Awakening from './fonts/Awakening.otf'
import BabyNames from './fonts/BabyNames.otf'
import BacktoBlack from './fonts/BacktoBlack.ttf'
import BeeKindMonoline from './fonts/BeeKindMonoline.ttf'
import BirdsofParadise from './fonts/BirdsofParadise.ttf'
import Blacksword from './fonts/Blacksword.otf'
import BrockScript from './fonts/BrockScript.ttf'
import CalisshaSans from './fonts/CalisshaSans.ttf'
import CarloMelow from './fonts/CarloMelow.otf'
import CarloMelowSans from './fonts/CarloMelowSans.otf'
import ChalisaOctavia from './fonts/ChalisaOctavia.otf'
import Charline from './fonts/Charline.otf'
import Cherolina from './fonts/Cherolina.ttf'
import ChickenQuiche from './fonts/ChickenQuiche.ttf'
import Chubby from './fonts/Chubby.otf'
import Chunkring from './fonts/Chunkring.ttf'
import ColumbiaSerialRegular from './fonts/ColumbiaSerialRegular.ttf'
import ComfortaaBold from './fonts/ComfortaaBold.ttf'
import ComfortaaLight from './fonts/ComfortaaLight.ttf'
import CreamCake from './fonts/CreamCake.otf'
import Crystallized from './fonts/Crystallized.ttf'
import Daddy from './fonts/Daddy.otf'
import Daydreamer from './fonts/Daydreamer.ttf'
import DecemberHolidays from './fonts/DecemberHolidays.ttf'
import Delightful from './fonts/Delightful.ttf'
import Energetica from './fonts/Energetica.ttf'
import EngraversGothicRegular from './fonts/EngraversGothicRegular.otf'
import Gardena from './fonts/Gardena.otf'
import hellohoney from './fonts/hellohoney.ttf'
import heroesassembleexpandital2 from './fonts/heroesassembleexpandital2.ttf'
import Highbright from './fonts/Highbright.ttf'
import ILoveGlitter from './fonts/ILoveGlitter.ttf'
import Imperator from './fonts/Imperator.ttf'
import ImprintMTShadow from './fonts/ImprintMTShadow.ttf'
import JosephSophia from './fonts/JosephSophia.ttf'
import KarimaRegular from './fonts/KarimaRegular.otf'
import KissingLove from './fonts/KissingLove.ttf'
import Lactosa from './fonts/Lactosa.otf'
import LaughingandSmiling from './fonts/LaughingandSmiling.ttf'
import LemonMilkProMedium from './fonts/LemonMilkProMedium.otf'
import LovelyHome from './fonts/LovelyHome.ttf'
import LoveTwist from './fonts/LoveTwist.ttf'
import Luna from './fonts/Luna.ttf'
import MadinaClean from './fonts/MadinaClean.ttf'
import Mellody from './fonts/Mellody.otf'
import Mellodyan from './fonts/Mellodyan.ttf'
import MyLovelyDemo from './fonts/MyLovelyDemo.ttf'
import Nathalia from './fonts/Nathalia.ttf'
import OctoberTwilight from './fonts/OctoberTwilight.ttf'
import Olivia from './fonts/Olivia.otf'
import OstrichSansInlineregular from './fonts/OstrichSansInlineregular.otf'
import PacificAgain from './fonts/PacificAgain.ttf'
import Pumkinpie from './fonts/Pumkinpie.ttf'
import QwertyAbility from './fonts/QwertyAbility.otf'
import RusticPantry from './fonts/RusticPantry.ttf'
import SalamanderScript from './fonts/SalamanderScript.otf'
import SimplicityDisplayRegular from './fonts/SimplicityDisplayRegular.ttf'
import StayDreaming from './fonts/StayDreaming.otf'
import SuperYeasty from './fonts/SuperYeasty.otf'
import TheKingOfRomance from './fonts/TheKingOfRomance.ttf'
import Vallentine from './fonts/Vallentine.otf'
import VampireCalligraphy from './fonts/VampireCalligraphy.ttf'
import VanillaCream from './fonts/VanillaCream.ttf'
import Vendetta from './fonts/Vendetta.otf'
import VINTAGECOLLEGEDEPTpure from './fonts/VINTAGECOLLEGEDEPTpure.otf'
import Watermelon from './fonts/Watermelon.ttf'
import WatermelonScript from './fonts/WatermelonScript.ttf'
import Wedding from './fonts/Wedding.ttf'
import WestwoodStudio from './fonts/WestwoodStudio.ttf'
import YessyFontbySitujuh from './fonts/YessyFontbySitujuh.otf'


const FONTS = {
  Affectionately: Affectionately,
  Ananda: Ananda,
  Andalucia: Andalucia,
  Anglena: Anglena,
  Ashburton: Ashburton,
  AthenaoftheOcean: AthenaoftheOcean,
  Awakening: Awakening,
  BabyNames: BabyNames,
  BacktoBlack: BacktoBlack,
  BeeKindMonoline: BeeKindMonoline,
  BirdsofParadise: BirdsofParadise,
  Blacksword: Blacksword,
  BrockScript: BrockScript,
  CalisshaSans: CalisshaSans,
  CarloMelow: CarloMelow,
  CarloMelowSans: CarloMelowSans,
  ChalisaOctavia: ChalisaOctavia,
  Charline: Charline,
  Cherolina: Cherolina,
  ChickenQuiche: ChickenQuiche,
  Chubby: Chubby,
  Chunkring: Chunkring,
  ColumbiaSerialRegular: ColumbiaSerialRegular,
  ComfortaaBold: ComfortaaBold,
  ComfortaaLight: ComfortaaLight,
  CreamCake: CreamCake,
  Crystallized: Crystallized,
  Daddy: Daddy,
  Daydreamer: Daydreamer,
  DecemberHolidays: DecemberHolidays,
  Delightful: Delightful,
  Energetica: Energetica,
  EngraversGothicRegular: EngraversGothicRegular,
  Gardena: Gardena,
  hellohoney: hellohoney,
  heroesassembleexpandital2: heroesassembleexpandital2,
  Highbright: Highbright,
  ILoveGlitter: ILoveGlitter,
  Imperator: Imperator,
  ImprintMTShadow: ImprintMTShadow,
  JosephSophia: JosephSophia,
  KarimaRegular: KarimaRegular,
  KissingLove: KissingLove,
  Lactosa: Lactosa,
  LaughingandSmiling: LaughingandSmiling,
  LemonMilkProMedium: LemonMilkProMedium,
  LovelyHome: LovelyHome,
  LoveTwist: LoveTwist,
  Luna: Luna,
  MadinaClean: MadinaClean,
  Mellody: Mellody,
  Mellodyan: Mellodyan,
  MyLovelyDemo: MyLovelyDemo,
  Nathalia: Nathalia,
  OctoberTwilight: OctoberTwilight,
  Olivia: Olivia,
  OstrichSansInlineregular: OstrichSansInlineregular,
  PacificAgain: PacificAgain,
  Pumkinpie: Pumkinpie,
  QwertyAbility: QwertyAbility,
  RusticPantry: RusticPantry,
  SalamanderScript: SalamanderScript,
  SimplicityDisplayRegular: SimplicityDisplayRegular,
  StayDreaming: StayDreaming,
  SuperYeasty: SuperYeasty,
  TheKingOfRomance: TheKingOfRomance,
  Vallentine: Vallentine,
  VampireCalligraphy: VampireCalligraphy,
  VanillaCream: VanillaCream,
  Vendetta: Vendetta,
  VINTAGECOLLEGEDEPTpure: VINTAGECOLLEGEDEPTpure,
  Watermelon: Watermelon,
  WatermelonScript: WatermelonScript,
  Wedding: Wedding,
  WestwoodStudio: WestwoodStudio,
  YessyFontbySitujuh: YessyFontbySitujuh,
}
export const FONTS_NAME_MAP = {}

Object.keys(FONTS).forEach((fontName, index) => {
  FONTS_NAME_MAP[fontName] = `Style-${index + 1}`
})


const getPatternId = (pattern) => `${pattern}-id-texture`
const getPatternFill = (pattern) => {
  const patternConfig = PATTERNS_CONFIG[pattern]
  const hasTexture = !!patternConfig?.texture
  if (!hasTexture) {
    return `${patternConfig?.fill ? patternConfig?.fill : '#ffff'}`
  }
  return `url(#${getPatternId(pattern)})`
}

// RED
// ORANGE
// LIGHT PINK
// MAGNETA
// IVORY
// YELLOW
// WHITE
// BROWN
// GRAY
// BLACK
// LIME GREEN
// LIGHT PURPLE
// ROYAL BLUE
// NAVY BLUE
// DARK GREEN
// PURPLE
const PATTERNS = {
  unpainted:  {
    name: "unpainted",
    label: "Unpainted",
    type: 'Natural',
    price: null,
  },
  gold: {
    name: "gold",
    label: "Paint - Metallic Gold",
    price: 15,
    type: 'Metallic'
  },
  silver: {
    name: "silver",
    label: "Paint - Metallic Silver",
    price: 15,
    type: 'Metallic'
  },
  roseGold: {
    name: "roseGold",
    label: "Paint - Metallic Rose Gold",
    price: 15,
    type: 'Metallic'
  },
  // sandStone: {
  //   name: "sandStone",
  //   label: "Stain - Sandstone",
  //   price: 10,
  //   type: 'Paint'
  // },
  // stoneAge: {
  //   name: "stoneAge",
  //   label: "Stain - Stone Age",
  //   price: 10,
  //   type: 'Paint'
  // },
  white: {
    name: "white",
    label: "Paint - White",
    price: 10,
    type: 'Paint'
  },
  black: {
    name: "black",
    label: "Paint - Black",
    price: 10,
    type: 'Paint'
  },
  purple: {
    name: "purple",
    label: "Paint - Purple",
    price: 10,
    type: 'Paint'
  },
  navy: {
    name: "navy",
    label: "Paint - Navy",
    price: 10,
    type: 'Paint'
  },
  grey: {
    name: "grey",
    label: "Paint - Gray",
    price: 10,
    type: 'Paint'
  },
  // pink: {
  //   name: "pink",
  //   label: "Paint - Pink",
  //   price: 10,
  //   type: 'Paint'
  // },
  // mint: {
  //   name: "mint",
  //   label: "Paint - Mint",
  //   price: 10,
  //   type: 'Paint'
  // },
  ivory: {
    name: "ivory",
    label: "Paint - Ivory",
    price: 10,
    type: 'Paint'
  },
  coral: {
    name: "coral",
    label: "Paint - Coral",
    price: 10,
    type: 'Paint'
  },
  blue: {
    name: "blue",
    label: "Paint - Blue",
    price: 10,
    type: 'Paint'
  },
  green: {
    name: "green",
    label: "Paint - Green",
    price: 10,
    type: 'Paint'
  },
  red: {
    name: "red",
    label: "Paint - Red",
    price: 10,
    type: 'Paint'
  },
  orange: {
    name: "orange",
    label: "Paint - Orange",
    price: 10,
    type: 'Paint'
  },
  yellow: {
    name: "yellow",
    label: "Paint - Yellow",
    price: 10,
    type: 'Paint'
  },
  lime: {
    name: "lime",
    label: "Paint - Lime",
    price: 10,
    type: 'Paint'
  },
  // lightBlue: {
  //   name: "lightBlue",
  //   label: "Paint - Light Blue",
  //   price: 10,
  //   type: 'Paint'
  // },
  lightPurple: {
    name: "lightPurple",
    label: "Paint - Light Purple",
    price: 10,
    type: 'Paint'
  },
  lightPink: {
    name: "lightPink",
    label: "Paint - Light Pink",
    price: 10,
    type: 'Paint'
  },

}
const PATTERNS_CONFIG = {
  [PATTERNS.unpainted.name]: {
    texture: (
      <pattern id={getPatternId(PATTERNS.unpainted.name)} patternUnits="userSpaceOnUse" width="2121" height="1413">
        <image xlinkHref="/images/textures/wood-texture.jpeg" x="0" y="0" width="2121" height="1413"/>
      </pattern>)
  },
  [PATTERNS.gold.name]: {
    texture: (
      <linearGradient id={getPatternId(PATTERNS.gold.name)} gradientTransform="rotate(90)">
        <stop offset="0" stopColor="#f2cf7a" opacity="1"/>
        <stop offset="0.5" stopColor="#dbb35c" opacity="1"/>
        <stop offset="1" stopColor="#d0a54d" opacity="1"/>
      </linearGradient>
    ),
  },
  [PATTERNS.silver.name]: {
    texture: (
      <linearGradient id={getPatternId(PATTERNS.silver.name)} gradientTransform="rotate(90)">
        <stop offset="0" stopColor="#c7c7c7" opacity="1"/>
        <stop offset="0.5" stopColor="#e4e4e4" opacity="1"/>
        <stop offset="1" stopColor="#c1c1c1" opacity="1"/>
      </linearGradient>
    ),
  },
  [PATTERNS.roseGold.name]: {
    texture: (
      <linearGradient id={getPatternId(PATTERNS.roseGold.name)} gradientTransform="rotate(90)">
        <stop offset="0" stopColor="#FFE8E5" opacity="1"/>
        <stop offset="0.5" stopColor="#d09c8b" opacity="1"/>
        <stop offset="1" stopColor="#EABFB9" opacity="1"/>
      </linearGradient>
    ),
  },
  // [PATTERNS.sandStone.name]: {
  //   texture: (
  //     <pattern id={getPatternId(PATTERNS.sandStone.name)} patternUnits="userSpaceOnUse" width="1300" height="800">
  //       <image xlinkHref="/images/textures/stain_0001_sandstone.jpeg" x="0" y="0" width="1300" height="800"/>
  //     </pattern>
  //   ),
  // },
  // [PATTERNS.stoneAge.name]: {
  //   texture: (
  //     <pattern id={getPatternId(PATTERNS.stoneAge.name)} patternUnits="userSpaceOnUse" width="1300" height="800">
  //       <image xlinkHref="/images/textures/stain_0004_stone_age.jpeg" x="0" y="0" width="1300" height="800"/>
  //     </pattern>
  //   ),
  // },
  [PATTERNS.white.name]: {
    fill: '#fff',
    invertBackground: true,
  },
  [PATTERNS.black.name]: {
    fill: '#000000'
  },
  [PATTERNS.purple.name]: {
    fill: '#57467a'
  },
  [PATTERNS.navy.name]: {
    fill: '#05294d'
  },
  [PATTERNS.grey.name]: {
    fill: '#9f9ea3'
  },
  // [PATTERNS.pink.name]: {
  //   fill: '#ec617e'
  // },
  // [PATTERNS.mint.name]: {
  //   fill: '#c7e4e2'
  // },
  [PATTERNS.ivory.name]: {
    fill: '#fbebca',
    invertBackground: true,
  },
  [PATTERNS.coral.name]: {
    fill: '#ef726c'
  },
  [PATTERNS.blue.name]: {
    fill: '#214788'
  },
  [PATTERNS.green.name]: {
    fill: '#17683d'
  },
  [PATTERNS.red.name]: {
    fill: '#c4393e'
  },
  [PATTERNS.orange.name]: {
    fill: '#ee754a'
  },
  [PATTERNS.yellow.name]: {
    fill: '#f8e05c',
    invertBackground: true,
  },
  [PATTERNS.lime.name]: {
    fill: '#9DC108'
  },
  [PATTERNS.lightPurple.name]: {
    fill: '#9699c6'
  },
  // [PATTERNS.lightBlue.name]: {
  //   fill: '#7ad0e9'
  // },
  [PATTERNS.lightPink.name]: {
    fill: '#f6d5e6'
  },
}

export {
  getPatternId,
  getPatternFill,
  PATTERNS,
  PATTERNS_CONFIG,
  FONTS
}
