import React from "react";

// Components Imports
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FONTS_NAME_MAP } from "../constants";


const FontCard = (props) => {
  const {fontName, text, setSelectedFont} = props;

  return (
    <div className="font-card-container">
      <Typography variant="caption">{FONTS_NAME_MAP[fontName]}</Typography>
      <div className="text-container" style={{fontFamily: fontName}}>
        {text}
      </div>
      <div className="details">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setSelectedFont(fontName)}
        >
          PICK THIS FONT
        </Button>
      </div>
    </div>
  );
}

export default FontCard;
