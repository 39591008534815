import MainApp from "./components/mainApp";

function App() {
  return (
    <div className="main">
      <MainApp />
    </div>
  );
}

export default App;
