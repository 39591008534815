import React, {useEffect} from "react";

// Components Imports
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// Local Imports
import {
  getPatternFill, PATTERNS_CONFIG,
} from '../constants';
import textToPath from "./utils";

const FinishCard = (props) => {
  const {font, pattern, setSelectedFinish, text} = props;
  const key = `${pattern.name} svg`;
  const patternConfig = PATTERNS_CONFIG[pattern.name];

  useEffect(() => {
    async function fetchData() {
      await textToPath(key, text, getPatternFill(pattern.name), font);
    }

    text && fetchData();
  }, [text, pattern, font, key])

  return (
    <div className="font-card-container">
      <Typography variant="caption">{pattern.label}</Typography>
      <div className="text-container-finish">
        <div style={{backgroundColor: patternConfig?.invertBackground ? '#000000' : null}}>
          <svg id={key} overflow="visible">
            <defs>
              {patternConfig.texture}
            </defs>
          </svg>
        </div>
      </div>
      <div className="details">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setSelectedFinish(pattern, font)}
        >
          PICK THIS FINISH {pattern.price ? `(+$${pattern.price})` : null}
        </Button>
      </div>
    </div>
  );
}

export default FinishCard;
