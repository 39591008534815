import { useCallback, useEffect, useState } from "react";

const usePostMessageListener = () => {
  const [eventData, setEventData] = useState({type: null, data: null})

  const handleMessage = useCallback(async (event) => {
    console.log('Recevied', event, event?.data)
    // Do we trust the sender of this message?  (might be
    // different from what we originally opened, for example).
    if (event.origin !== "https://www.shopmannythings.com")
      return;

    const {type = null, data = null} = event?.data || {}
    setEventData({type, data})
  }, [setEventData])

  useEffect(() => {
    window.addEventListener("message", handleMessage, false);
    return () => {
      window.removeEventListener("message", handleMessage, false)
    }
  }, [handleMessage])

  return eventData
}

export default usePostMessageListener